import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import threecrowsLogo from './assets/images/threecrows-logo-darkmode.svg';
import threecrowsText from './assets/images/threecrows-text-darkmode.svg';
import threecro_wsText from './assets/images/threecro.ws-text-darkmode.svg';
import verticalDivider from './assets/images/vertical-divider.svg';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
    </Router>
  );
}

export default App;

/**
 * Components
 */

function Home() {
  return (
    <div className="App App__Home">
      <header className="App-header">
        <img
          src={threecrowsLogo}
          className="threecrows__logo"
          alt="threecro.ws"
        />
        <h1>
          <img
            src={threecrowsText}
            className="threecrows__text"
            alt="THREECROWS"
          />
        </h1>
        <small>
          <img
            src={threecro_wsText}
            className="threecro_ws__text"
            alt="threecro.ws"
          />
        </small>
        <ThreeCrowsInstagramLink />
      </header>
      <footer className="App-footer">
        <Link to="/disclaimer">Disclaimer</Link>
        <img src={verticalDivider} className="vertical-divider" alt="|" />
        <a href="mailto:info@threecro.ws">info@threecro.ws</a>
      </footer>
    </div>
  );
}

function Disclaimer() {
  return (
    <div className="App App__Disclaimer">
      <header className="App-header">
        <img
          src={threecrowsLogo}
          className="threecrows__logo"
          alt="threecro.ws"
        />
        <h1>
          <img
            src={threecrowsText}
            className="threecrows__text"
            alt="THREECROWS"
          />
        </h1>
        <small>
          <img
            src={threecro_wsText}
            className="threecro_ws__text"
            alt="threecro.ws"
          />
        </small>
      </header>
      <div className="content m-l-20">
        <h2>Disclaimer</h2>
        <span className="post-disclaimer">
          website: threecro.ws, instagram: @_threecrows, twitter: @_threecrows
        </span>
        <p>
          WEALTH WARNING: You should only use money you can afford to lose, as trading the financial markets can be risky and you can lose all your deposited money.
        </p>
        <p>
          All investing and trading in the securities market involves risk. Any decision or decisions to place trades in the financial markets, including trading in stock or options or other financial instruments, is your own personal decision that should only be made after thorough research, including your personal risk and financial assessment, and the engagement of professional assistance to the extent you believe necessary.
        </p>
        <p>
          Any and all opinions, commentary, news, research, analyses, prices, or other information contained on this website or on our social media channels are provided as general market commentary, and do not constitute investment advice nor a solicitation and there are no guarantees associated with them. We are not liable for any loss or damage, including without limitation, any loss of profit, which may arise directly or indirectly from use of or reliance on such information. We have taken reasonable measures to ensure the accuracy of the information on the website. The content on this website is subject to change at any time without notice.
        </p>
        <p>
          Use of the Site and our materials and Services is solely at your own risk. threecro.ws, its affiliates, and their respective officers, directors, shareholders, agents and employees expressly disclaim all liability regarding your use or inability to use the Site and our materials and Services.
        </p>
      </div>
      <footer className="App-footer">
        <Link to="/">threecro.ws</Link>
        <img src={verticalDivider} className="vertical-divider" alt="|" />
        <a href="mailto:info@threecro.ws">info@threecro.ws</a>
        <img src={verticalDivider} className="vertical-divider" alt="|" />
        <ThreeCrowsInstagramLink />
      </footer>
    </div>
  );
}

function ThreeCrowsInstagramLink() {
  return (
    <a
      className="_threecrows-link"
      href="https://instagram.com/_threecrows/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <InstagramLogo />
      @_threecrows
    </a>
  );
}

function InstagramLogo() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20.29" height="20.29" viewBox="0 0 20.29 20.29" className="_threecrows-link__icon"><g transform="translate(-896.947 -9091.947)"><path d="M14.246,0h-8.2A6.051,6.051,0,0,0,0,6.045v8.2A6.051,6.051,0,0,0,6.045,20.29h8.2a6.051,6.051,0,0,0,6.045-6.045v-8.2A6.051,6.051,0,0,0,14.246,0Zm4,14.246a4,4,0,0,1-4,4h-8.2a4,4,0,0,1-4-4v-8.2a4,4,0,0,1,4-4h8.2a4,4,0,0,1,4,4v8.2Z" transform="translate(896.947 9091.947)" fill="#fff"/><path d="M138.006,133a5.006,5.006,0,1,0,5.006,5.006A5.012,5.012,0,0,0,138.006,133Zm0,8.065a3.059,3.059,0,1,1,3.059-3.059A3.059,3.059,0,0,1,138.006,141.065Z" transform="translate(769.086 8964.086)" fill="#fff"/><circle cx="1.319" cy="1.319" r="1.319" transform="translate(910.994 9095.601)" fill="#fff"/></g></svg>;
}